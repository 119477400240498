import * as React from 'react';

import GeneralLayout from '../components/GeneralLayout';
import Seo from '../components/seo';

function NotFoundPage() {
  return (
    <GeneralLayout>
      <Seo title="404: Not found" />
      <div className="info">
        <div className="result">
          <div className="result-content 404">
            <div className="result-header">
              <h1 className="404">404: Content not found.</h1>
            </div>
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
}

export default NotFoundPage;
